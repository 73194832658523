<template>
  <div>
    <v-card>
      <v-card color="primary" min-height="10" flat tile class="pa-1">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn
            @click="closePanel"
            x-small
            fab
            depressed
            color="black"
            dark
            id="btn-close"
            class="mr-1"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="pa-4">
        <v-card-title>
          <span class="font-weight-regular">
            <v-icon class="mr-2">mdi-domain</v-icon>Regla
          </span>
          <v-spacer />
          <span class="primary--text">
            {{ object.ruleCode }}
          </span>
        </v-card-title>
        <v-card class="pa-2" flat>
          <v-card-title class="body-1 font-weight-bold">
            Información de la regla
          </v-card-title>
          <v-card outlined class="pa-6" v-if="object">
            <v-row align="stretch" justify="start">
              <v-col cols="12" sm="12" md="6">
                <span class="body-1">Nivel de prioridad</span>
                <v-chip
                  :color="getPriorityColor(object.rulePriority)"
                  class="ml-2"
                >
                  {{ object.ruleGroupPriority }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <span class="body-1">Seller</span>
                <span class="ml-2 font-weight-bold">
                  {{ sellers[object.sellerId] || object.sellerId }}
                </span>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <span class="body-1">Consumer</span>
                <span class="ml-2 font-weight-bold">
                  {{ consumers[object.consumerId] || object.consumerId }}
                </span>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <span class="body-1">Fecha efectiva</span>
                <span class="ml-2 font-weight-bold">
                  {{ object.effectiveDate | date }}
                </span>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <span class="body-1">Fecha expiración</span>
                <span class="ml-2 font-weight-bold">
                  {{ object.expireDate | date }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-card>

        <v-card class="pa-2" flat>
          <v-card-title class="body-1 font-weight-bold">
            Tipos de atributos asociados
          </v-card-title>
          <v-card outlined class="pa-4">
            <v-row align="stretch" justify="start">
              <v-card-text
                v-if="object.attributes && object.attributes.length === 0"
              >
                No se encontraron atributos asociados a esta regla
              </v-card-text>
              <v-card-text v-else>
                <v-menu
                  bottom
                  v-for="(attribute, i) in object.attributes"
                  :key="i"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="ma-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getAttributeText(attribute.attributeCode) }}
                    </v-chip>
                  </template>

                  <v-card flat class="pa-4">
                    Valores asociados
                    <v-list>
                      <v-list-item
                        v-for="(value, index) in attribute.attributeValue"
                        :key="index"
                      >
                        <v-list-item-title>{{
                          getValueAttributeText(attribute.attributeCode, value)
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-card-text>
            </v-row>
          </v-card>
        </v-card>

        <v-card class="pa-2" flat>
          <v-card-title class="body-1 font-weight-bold">
            Tipos de condiciones de productos asociadas
          </v-card-title>
          <v-card outlined class="pa-4">
            <v-row align="stretch" justify="start">
              <v-card-text
                v-if="
                  object.productConditions &&
                    object.productConditions.length === 0
                "
              >
                No se encontraron condiciones de productos asociados a esta
                regla
              </v-card-text>
              <v-card-text v-else>
                <v-menu
                  bottom
                  v-for="(condition, i) in object.productConditions"
                  :key="i"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      class="ma-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getConditionText(condition.conditionCode) }}
                    </v-chip>
                  </template>

                  <v-card flat class="pa-4">
                    ¿Activo?
                    <div>
                      {{ condition.answer.answerBoolean ? "Si" : "No" }}
                    </div>
                  </v-card>
                </v-menu>
              </v-card-text>
            </v-row>
          </v-card>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
/**
 * Show panel form
 * @author Cristian Molina
 */
import sellersJson from "@/assets/json/sellers";
import consumersJson from "@/assets/json/consumers";
import attributes from "@/assets/json/attributes.json";
import productCondition from "@/assets/json/productCondition.json";

export default {
  name: "panel",
  props: ["headers", "object", "isEditing", "priorityColor"],
  data: () => ({
    sellers: [],
    consumers: [],
    menu: false,
    rules: [],
    rulePriorityColor: {
      red: {
        from: 0,
        to: 0,
        color: "error",
      },
      yellow: {
        from: 0,
        to: 0,
        color: "warning",
      },
      green: {
        from: 0,
        to: 0,
        color: "success",
      },
    },
  }),
  /**
   * @description Mounted instance of component
   * @author Cristian Molina
   */
  mounted() {
    this.sellers = sellersJson;
    this.consumers = consumersJson;
  },
  methods: {
    /**
     * @description
     * @param {any} v
     * @returns {any}
     * @author Cristian Molina
     */
    getPriorityColor(v) {
      if (this.priorityColor) {
        const keys = Object.keys(this.priorityColor);
        let color = "";
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
            this.priorityColor[key].from <= v &&
            this.priorityColor[key].to >= v
          ) {
            color = this.priorityColor[key].color;
          }
        }
        if (color === "") {
          color = "accent";
        }
        return color;
      }
    },
    /**
     * @description
     * @author Cristian Molina
     */
    closePanel() {
      this.$emit("ClosePanel");
    },
    getConditionText(val) {
      let res = null;
      productCondition.forEach((condition) => {
        if (condition.code === val) {
          res = condition.text;
        }
      });
      return res;
    },
    getAttributeText(val) {
      let res = null;
      attributes.forEach((attribute) => {
        if (attribute.attributeCode === val) {
          res = attribute.text;
        }
      });
      return res;
    },
    getValueAttributeText(code, val) {
      let res = null;
      attributes.forEach((attribute) => {
        if (attribute.attributeCode === code) {
          if (attribute.items) {
            attribute.items.forEach((item) => {
              if (item.value === val) {
                res = item.text;
              }
            });
          } else if (typeof val === "boolean") {
            res = val ? "Si" : "No";
          }
        }
      });
      return res;
    },
  },
  watch: {
    /**
     * @description
     * @param {any} v
     * @author Cristian Molina
     */
    object(v) {
      if (v) {
        this.getRulesByGroup(v);
      }
    },
  },
};
</script>
